<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="flex flex-col w-full" v-show="!isLoading">
      <div class="w-11/12 flex mx-auto justify-between">
        <router-link
          :to="{ name: 'course' }"
          class="bg-green-1000 hover:bg-green-1001 text-white px-4 py-2 rounded-lg focus:outline-none"
        >
          <svg
            style="display: initial;"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Voltar
        </router-link>
      </div>
      <div class="w-11/12 flex mx-auto justify-between">
        <h1 class="text-4xl">
          Atualizar curso
        </h1>
      </div>
      <form @submit.prevent="submit" class="w-11/12 mx-auto mt-5">
        <div class="border border-green-1000 rounded-lg py-3 px-5">
          <div class="flex w-full mb-5">
            <div class="flex w-8/12 flex-col mr-5">
              <label class="mb-2" for="name">Curso</label>
              <input
                v-model="course.name"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name"
                type="text"
                readonly
              />
            </div>
            <div class="flex w-4/12 flex-col mr-5">
              <label class="mb-2" for="programming_nature"
                >Natureza da programação</label
              >
              <input
                v-model="course.programming_nature"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="programming_nature"
                type="text"
                readonly
              />
            </div>
          </div>
          <div class="flex w-full mb-5">
            <div class="flex w-3/12 flex-col mr-5">
              <label class="mb-2" for="programming_type"
                >Tipo de programação</label
              >
              <input
                v-model="course.programming_nature"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="programming_type"
                type="text"
                readonly
              />
            </div>
            <div class="flex w-10/12 flex-col mr-5">
              <label class="mb-2" for="occupational_area"
                >Área ocupacional</label
              >
              <input
                v-model="course.occupational_area"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="occupational_area"
                type="text"
                readonly
              />
            </div>
          </div>
          <div class="flex w-full mb-5">
            <div class="flex w-8/12 flex-col mr-5">
              <label class="mb-2" for="cbo_activity">CBO/Atividade</label>
              <input
                v-model="course.cbo_activity"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="cbo_activity"
                type="text"
                readonly
              />
            </div>

            <div class="flex w-4/12 flex-col mr-5">
              <label class="mb-2" for="action_line"
                >Linha de ação/Área de atividade PS</label
              >
              <input
                v-model="course.action_line"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="action_line"
                type="text"
                readonly
              />
            </div>
          </div>
          <div class="flex w-full mb-5">
            <div class="flex w-6/12 flex-col mr-5">
              <label class="mb-2" for="activity_areas"
                >Área de atividades</label
              >
              <textarea
                v-model="course.activity_areas"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="activity_areas"
                rows="5"
                readonly
              ></textarea>
            </div>

            <div class="flex w-6/12 flex-col mr-5">
              <label class="mb-2" for="cbo_activities">Atividades CBO</label>
              <textarea
                v-model="course.cbo_activities"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="cbo_activities"
                rows="5"
                readonly
              ></textarea>
            </div>
          </div>
          <div class="flex w-full mb-5">
            <div class="flex-col w-full mb-5">
              <div class="flex w-12/12 flex-col mr-5">
                <label class="mb-2" for="objective">Objetivo</label>
                <textarea
                  v-model="course.objective"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="objective"
                  rows="5"
                  readonly
                ></textarea>
              </div>
              <div class="flex w-12/12 flex-col mr-5">
                <label class="mb-2" for="keywords">Palavras chaves</label>
                <vue-tags-input
                  id="keywords"
                  class="outline-none rounded-md border w-full "
                  rows="5"
                  ref="keywords"
                  v-model="course.keyword"
                  :keywords="course.keywords"
                  placeholder="Adicione as palavras chaves desejadas"
                  @tags-changed="newKeywords => (course.keywords = newKeywords)"
                />
              </div>
            </div>
            <div class="flex w-10/12 flex-col mr-5">
              <label class="mb-2" for="programmatic_content"
                >Conteúdo programático</label
              >
              <textarea
                v-model="course.programmatic_content"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="programmatic_content"
                rows="20"
                readonly
              >
              </textarea>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button
            type="submit"
            class="bg-green-1000 hover:bg-green-1001 text-white py-2 px-4 rounded-lg focus:outline-none mt-5"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  </dashboard-layout>
</template>
<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import VueTagsInput, { createTag } from '@johmun/vue-tags-input';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  components: {
    DashboardLayout,
    VueTagsInput,
    LoaderAnimation
  },

  props: {
    id: {
      type: String
    }
  },

  data() {
    return {
      isLoading: true,
      course: {
        name: '',
        programming_nature: '',
        programming_type: '',
        objective: '',
        programmatic_content: '',
        occupational_area: '',
        action_line: '',
        cbo_activity: '',
        cbo_activities: '',
        activity_areas: '',
        keyword: '',
        keywords: []
      }
    };
  },

  methods: {
    submit() {
      axios
        .patch('/api/cursos/' + this.id, {
          keywords: this.course.keywords
        })
        .then(() => {
          this.$router.push({ name: 'course' });

          this.$toast.success('Curso atualizado com sucesso.');
        })
        .catch(error => {
          this.$toast.error(error.response.data.errors.keywords[0]);
        });
    },

    getCourse: function() {
      axios.get('/api/cursos/' + this.id).then(response => {
        this.course.name = response.data.name;
        this.course.programming_nature = response.data.programming_nature;
        this.course.action_line = response.data.action_line;
        this.course.activity_areas = response.data.activity_areas;
        this.course.cbo_activities = response.data.cbo_activities;
        this.course.cbo_activity = response.data.cbo_activity;
        this.course.objective = response.data.objective;
        this.course.occupational_area = response.data.occupational_area;
        this.course.programmatic_content = response.data.programmatic_content;
        this.course.programming_type = response.data.programming_type;

        response.data.keywords.forEach(keyword => {
          let tag = createTag(keyword, [keyword]);
          this.$refs.keywords.addTag(tag);
        });

        this.isLoading = false;
      });
    }
  },

  created: function() {
    this.getCourse();
  }
};
</script>

<style scoped></style>
